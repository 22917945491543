











import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import HomeLoanCalculator from '@/components/Calculators/HomeLoanCalculator.vue';

@Component({
  components: {
    HomeLoanCalculator,
  },
})
export default class Calculators extends Mixins(View) {
  private propertyValue = 0;

  private stampDutyState = '';

  private firstHomeBuyer = 'yes';

  private propertyType = 'primary';

  private purchasing = 'Established Home';

  private eligiblePensioner = 'yes';

  private stampDuty = 0;

  private propertyPrice = 0;

  private weeklyRent = 0;

  private yearlyRates = 0;

  private yearlyInsurance = 0;

  private yearlyBCF = 0;

  private annualRentalIncome = 0;

  private rentalYield = 0;

  calculateRentalYield() {
    this.annualRentalIncome = this.weeklyRent * 52 - this.yearlyRates - this.yearlyInsurance - this.yearlyBCF;
    const rentalYield = (this.annualRentalIncome / this.propertyPrice) * 100;
    this.rentalYield = Math.round(rentalYield * 100) / 100;
  }

  calculateStampDuty() {
    switch (this.stampDutyState) {
      case 'NSW':
        break;
      case 'VIC':
        break;
      case 'QLD':
        this.calculateStampDutyForQLD();
        break;
      case 'ACT':
        break;
      case 'NT':
        break;
      case 'SA':
        break;
      case 'WA':
        this.calculateStampDutyForWA();
        break;
      case 'TAS':
        break;
      default:
        alert('Please Select a State');
    }
  }

  calculateStampDutyForQLD() {
    let stampDuty = 0;
    let concession = 0;
    if (this.propertyType === 'primary') {
      if (this.propertyValue <= 350000) {
        stampDuty = (this.propertyValue / 100) * 1;
      } else if (this.propertyValue > 350000 && this.propertyValue <= 540000) {
        stampDuty = 3500 + ((this.propertyValue - 350000) / 100) * 3.5;
      } else if (this.propertyValue > 540000 && this.propertyValue <= 1000000) {
        stampDuty = 10150 + ((this.propertyValue - 540000) / 100) * 4.5;
      } else {
        stampDuty = 30850 + ((this.propertyValue - 1000000) / 100) * 5.75;
      }
    }
    if (this.propertyType === 'investment') {
      if (this.propertyValue > 5000 && this.propertyValue <= 75000) {
        stampDuty = ((this.propertyValue - 5000) / 100) * 1.5;
      } else if (this.propertyValue > 75000 && this.propertyValue <= 540000) {
        stampDuty = 1050 + ((this.propertyValue - 75000) / 100) * 3.5;
      } else if (this.propertyValue > 540000 && this.propertyValue <= 1000000) {
        stampDuty = 17325 + ((this.propertyValue - 540000) / 100) * 4.5;
      } else {
        stampDuty = 38025 + ((this.propertyValue - 1000000) / 100) * 5.75;
      }
    }
    if (this.firstHomeBuyer === 'yes') {
      if (this.propertyValue <= 709999.99) {
        concession = 17350;
      } else if (this.propertyValue <= 719999.99) {
        concession = 15615;
      } else if (this.propertyValue <= 729999.99) {
        concession = 13880;
      } else if (this.propertyValue <= 739999.99) {
        concession = 12145;
      } else if (this.propertyValue <= 749999.99) {
        concession = 10410;
      } else if (this.propertyValue <= 759999.99) {
        concession = 8675;
      } else if (this.propertyValue <= 769999.99) {
        concession = 6940;
      } else if (this.propertyValue <= 779999.99) {
        concession = 5205;
      } else if (this.propertyValue <= 789999.99) {
        concession = 3470;
      } else if (this.propertyValue <= 799999.99) {
        concession = 1735;
      } else {
        concession = 0;
      }
    }
    if (this.firstHomeBuyer === 'yes' && this.purchasing === 'Vacant Land') {
      if (this.propertyValue <= 359999.99) {
        concession = 10675;
      } else if (this.propertyValue <= 369999.99) {
        concession = 9965;
      } else if (this.propertyValue <= 379999.99) {
        concession = 9255;
      } else if (this.propertyValue <= 389999.99) {
        concession = 8545;
      } else if (this.propertyValue <= 399999.99) {
        concession = 7835;
      } else if (this.propertyValue <= 409999.99) {
        concession = 7125;
      } else if (this.propertyValue <= 419999.99) {
        concession = 6415;
      } else if (this.propertyValue <= 429999.99) {
        concession = 5705;
      } else if (this.propertyValue <= 439999.99) {
        concession = 4995;
      } else if (this.propertyValue <= 449999.99) {
        concession = 4285;
      } else if (this.propertyValue <= 459999.99) {
        concession = 3575;
      } else if (this.propertyValue <= 469999.99) {
        concession = 2865;
      } else if (this.propertyValue <= 479999.99) {
        concession = 2155;
      } else if (this.propertyValue <= 489999.99) {
        concession = 1445;
      } else if (this.propertyValue <= 499999.99) {
        concession = 735;
      } else {
        concession = 0;
      }
    }
    if (concession > stampDuty) {
      this.stampDuty = 0;
    } else {
      this.stampDuty = stampDuty - concession;
    }
  }

  calculateStampDutyForWA() {
    let stampDuty = 0;
    switch (this.firstHomeBuyer) {
      case 'no':
        stampDuty = this.generalDutyRateWA();
        break;
      case 'yes':
        if (this.propertyType === 'investment') {
          stampDuty = this.generalDutyRateWA();
        } else if (this.purchasing === 'Vacant Land') {
          stampDuty = this.firstHomeVacantLandDutyWA();
        } else {
          stampDuty = this.firstHomeDutyWA();
        }
        break;
      default:
        this.generalDutyRateWA();
        break;
    }
    this.stampDuty = stampDuty;
  }

  generalDutyRateWA() {
    let stampDuty = 0;
    if (this.propertyValue <= 120000) {
      stampDuty = (this.propertyValue / 100) * 1.90;
    } else if (this.propertyValue <= 150000) {
      stampDuty = 2280 + ((this.propertyValue - 120000) / 100) * 2.85;
    } else if (this.propertyValue <= 360000) {
      stampDuty = 3135 + ((this.propertyValue - 150000) / 100) * 3.80;
    } else if (this.propertyValue <= 725000) {
      stampDuty = 11115 + ((this.propertyValue - 360000) / 100) * 4.75;
    } else {
      stampDuty = 28453 + ((this.propertyValue - 725000) / 100) * 5.15;
    }
    return stampDuty;
  }

  firstHomeVacantLandDutyWA() {
    let stampDuty = 0;
    if (this.propertyValue <= 300000) {
      stampDuty = 0;
    } else if (this.propertyValue <= 400000) {
      stampDuty = ((this.propertyValue - 300000) / 100) * 13.01;
    } else {
      stampDuty = this.generalDutyRateWA();
    }
    return stampDuty;
  }

  firstHomeDutyWA() {
    let stampDuty = 0;
    if (this.propertyValue <= 450000) {
      stampDuty = 0;
    } else if (this.propertyValue <= 600000) {
      stampDuty = ((this.propertyValue - 450000) / 100) * 15.01;
    } else {
      stampDuty = this.generalDutyRateWA();
    }
    return stampDuty;
  }
}
